<template>
  <Button v-bind="props" class="ToggleBarButton">
    <slot></slot>
  </Button>
</template>

<script setup lang="ts">
import type { Props as ButtonProps } from "~/components/ui/Button/Button.vue";

const props = defineProps<ButtonProps>();
</script>

<style scoped lang="scss">
.ToggleBarButton {
  &:focus {
    outline: none !important;
    border-color: var(--gray-5) !important;
  }
}
</style>
